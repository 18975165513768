/**
 * Navigation components.
 * @module components/theme/Navigation/Navigation
 */

import React, { Component } from 'react';
import { isMatch } from 'lodash';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { bindActionCreators } from 'redux';
import { defineMessages, injectIntl } from 'react-intl';
import { Menu, Segment, Button, Icon } from 'semantic-ui-react';
import cx from 'classnames';
import { doesNodeContainClick } from 'semantic-ui-react/dist/commonjs/lib';
import config from '@plone/volto/registry';

import { getNavigation } from '@plone/volto/actions';
import {
  BodyClass,
  getBaseUrl,
  flattenToAppURL,
  isInternalURL,
} from '@plone/volto/helpers';

import UniversalLink from '@plone/volto/components/manage/UniversalLink/UniversalLink';

const messages = defineMessages({
  closeMobileMenu: {
    id: 'Close menu',
    defaultMessage: 'Close menu',
  },
  openMobileMenu: {
    id: 'Open menu',
    defaultMessage: 'Open menu',
  },
});

/**
 * Navigation container class.
 * @class Navigation
 * @extends Component
 */
class Navigation extends Component {
  /**
   * Default properties.
   * @property {Object} defaultProps Default properties.
   * @static
   */
  static propTypes = {
    getNavigation: PropTypes.func.isRequired,
    pathname: PropTypes.string.isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        url: PropTypes.string,
      }),
    ).isRequired,
  };

  /**
   * Constructor
   * @method constructor
   * @param {Object} props Component properties
   * @constructs Navigation
   */
  constructor(props) {
    super(props);
    this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
    this.closeMobileMenu = this.closeMobileMenu.bind(this);
    this.state = {
      isMobileMenuOpen: false,
      desktopMenuOpen: null,
      activeSubMenu: null,
    };
  }

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    this.props.getNavigation(
      getBaseUrl(this.props.pathname),
      config.settings.navDepth,
    );
    document.addEventListener('mousedown', this.handleClickOutside, false);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside, false);
  }
  /**
   * Component will receive props
   * @method componentWillReceiveProps
   * @param {Object} nextProps Next properties
   * @returns {undefined}
   */
  componentWillReceiveProps(nextProps) {
    if (nextProps.pathname !== this.props.pathname) {
      this.props.getNavigation(
        getBaseUrl(nextProps.pathname),
        config.settings.navDepth,
      );
    }
  }

  /**
   * Check if menu is active
   * @method isActive
   * @param {string} url Url of the navigation item.
   * @returns {bool} Is menu active?
   */
  isActive(url) {
    return (
      (url === '' && this.props.pathname === '/') ||
      (url !== '' && isMatch(this.props.pathname.split('/'), url.split('/')))
    );
  }

  handleClickOutside = (e) => {
    if (
      this.navigation.current &&
      doesNodeContainClick(this.navigation.current, e)
    )
      return;
    this.closeMenu();
  };

  openMenu = (index) => {
    this.setState({
      desktopMenuOpen: index,
    });
  };

  closeMenu = (index) => {
    this.setState({
      desktopMenuOpen: null,
    });
  };

  navigation = React.createRef();
  navigationItems = React.createRef();

  /**
   * Toggle mobile menu's open state
   * @method toggleMobileMenu
   * @returns {undefined}
   */
  toggleMobileMenu() {
    this.setState({
      isMobileMenuOpen: !this.state.isMobileMenuOpen,
      activeSubMenu: null,
    });
  }

  /**
   * Close mobile menu
   * @method closeMobileMenu
   * @returns {undefined}
   */
  closeMobileMenu() {
    if (!this.state.isMobileMenuOpen) {
      return;
    }
    this.setState({ isMobileMenuOpen: false });
  }

  /**
   * Render method.
   * @method render
   * @returns {string} Markup for the component.
   */
  render() {
    const navItems = this.props.items;

    const returnParameter = this.props.content
      ? `?return_url=${this.props.content['@id'].replace(
          config.settings.apiPath,
          '',
        )}`
      : '';
    const loginUrl = `/login${returnParameter}`;
    return (
      <div className="navigation" ref={this.navigation}>
        {this.state.isMobileMenuOpen && (
          <BodyClass className="open-mobile-menu" />
        )}
        <Segment basic className="mobile only">
          {this.state.activeSubMenu !== null && this.state.isMobileMenuOpen && (
            <Button
              className="item back"
              onClick={() => this.setState({ activeSubMenu: null })}
            >
              {'< Zurück'}
            </Button>
          )}
          <button
            className={
              this.state.isMobileMenuOpen ? 'hamburger active' : 'hamburger'
            }
            title={
              this.state.isMobileMenuOpen
                ? this.props.intl.formatMessage(messages.closeMobileMenu, {
                    type: this.props.type,
                  })
                : this.props.intl.formatMessage(messages.openMobileMenu, {
                    type: this.props.type,
                  })
            }
            type="button"
            onClick={this.toggleMobileMenu}
          >
            <span className="hamburger-box">
              <span className="hamburger-inner" />
            </span>
          </button>
        </Segment>
        <Menu
          stackable
          pointing
          secondary
          className={cx(
            this.state.isMobileMenuOpen
              ? 'open'
              : 'tablet computer large screen widescreen only',
            { 'with-search-focused': this.props.isSearchFocused },
          )}
        >
          <ul className="desktop-menu tablet computer large screen widescreen only">
            {navItems.slice(0, 5).map((item, index) => (
              <li>
                <button
                  onClick={() =>
                    !this.state.isMobileMenuOpen
                      ? this.openMenu(index)
                      : window.open(item.url, '_self')
                  }
                  className={cx('item', {
                    active: this.state.desktopMenuOpen === index,
                  })}
                >
                  {item.title}
                </button>
                <div className="submenu-wrapper">
                  <div
                    className={cx('full-width submenu', {
                      active: this.state.desktopMenuOpen === index,
                    })}
                  >
                    <div className="left-submenu">
                      <div className="image-wrapper">
                        <img
                          src={`${item.url}/@@images/preview_image`}
                          alt="preview"
                        ></img>
                      </div>
                    </div>
                    <div className="divider"></div>
                    <div className="submenu-inner">
                      {/* <Link
                        to={item.url === '' ? '/' : item.url}
                        onClick={() => this.closeMenu()}
                        className={this.isActive(item.url) ? 'active' : ''}
                      >
                        <h2>{item.title}</h2>
                      </Link> */}
                      <ul>
                        {item.items &&
                          item.items.length > 0 &&
                          item.items.slice(0, 5).map((subitem) => (
                            <div className="subitem-wrapper">
                              <li key={subitem.url}>
                                {subitem?.blank_link ? (
                                  <UniversalLink
                                    href={flattenToAppURL(subitem.remoteUrl)}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => this.closeMenu()}
                                  >
                                    <span>
                                      {subitem.nav_title || subitem.title}
                                    </span>
                                  </UniversalLink>
                                ) : subitem.remoteUrl &&
                                  !isInternalURL(subitem.remoteUrl) ? (
                                  <a
                                    href={subitem.remoteUrl}
                                    onClick={() => this.closeMenu()}
                                  >
                                    <span>
                                      {subitem.nav_title || subitem.title}
                                    </span>
                                  </a>
                                ) : (
                                  <Link
                                    to={
                                      subitem.remoteUrl
                                        ? flattenToAppURL(subitem.remoteUrl)
                                        : subitem.url
                                    }
                                    onClick={() => this.closeMenu()}
                                  >
                                    <span>
                                      {subitem.nav_title || subitem.title}
                                    </span>
                                  </Link>
                                )}
                              </li>
                            </div>
                          ))}
                      </ul>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <ul className="desktop-menu mobile only">
            {this.state.activeSubMenu === null ? (
              navItems.slice(0, 5).map((item, index) => (
                <li>
                  {item.items.length > 0 ? (
                    <button
                      onClick={() => this.setState({ activeSubMenu: index })}
                      className={cx('item', {
                        active: this.state.desktopMenuOpen === index,
                      })}
                    >
                      {item.title}
                    </button>
                  ) : (
                    <Link
                      to={item.url}
                      onClick={() => {
                        this.closeMobileMenu();
                        this.setState({ activeSubMenu: null });
                      }}
                      className="item"
                    >
                      {item.title}
                    </Link>
                  )}
                </li>
              ))
            ) : (
              <>
                {navItems[this.state.activeSubMenu].items.map((item, index) => (
                  <li>
                    <Link
                      to={item.url}
                      onClick={() => {
                        this.closeMobileMenu();
                        this.setState({ activeSubMenu: null });
                      }}
                      className="item"
                    >
                      {item.title}
                    </Link>
                  </li>
                ))}
              </>
            )}
          </ul>
          {!this.props.token && (
            <Segment basic className="anontools mobile only">
              <div className="button-wrapper">
                <Link
                  className="item"
                  to={loginUrl}
                  onClick={() => {
                    this.closeMobileMenu();
                  }}
                >
                  <Icon
                    name="chevron right"
                    size="small"
                    className="fontawesome"
                  />{' '}
                  Anmelden
                </Link>
                <Button
                  id="login-button"
                  as={Link}
                  to="/register"
                  content="Registrieren"
                  icon="sign in"
                  labelPosition="left"
                  color="blue"
                  onClick={() => {
                    this.closeMobileMenu();
                  }}
                />
              </div>
            </Segment>
          )}
          {this.props.token && (
            <Segment basic className="anontools mobile only">
              <div className="personaltools">
                <Link
                  className="item"
                  to="/logout"
                  onClick={() => {
                    this.closeMobileMenu();
                  }}
                >
                  <Icon
                    name="chevron right"
                    size="small"
                    className="fontawesome"
                  />{' '}
                  Abmelden
                </Link>
                <Button
                  as={Link}
                  to="/mein-profil"
                  content="Mein Profil"
                  icon="user"
                  labelPosition="left"
                  color="blue"
                />
              </div>
            </Segment>
          )}
        </Menu>
      </div>
    );
  }
}

export default compose(
  injectIntl,
  connect(
    (state) => ({
      items: state.navigation.items,
      token: state.userSession.token,
    }),
    (dispatch) => bindActionCreators({ getNavigation }, dispatch),
  ),
)(Navigation);
