import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findIndex } from 'lodash';
import { Button, Icon } from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import { flattenToAppURL } from '@plone/volto/helpers';

import { searchContent } from '@plone/volto/actions';

/**
 *
 * @class NextPreviousNav
 * @extends {Component}
 */
class NextPreviousNav extends Component {
  /**
   * Property types.
   * @property {Object} propTypes Property types.
   * @static
   */
  static propTypes = {
    searchContent: PropTypes.func.isRequired,
    content: PropTypes.shape({
      id: PropTypes.string,
      parent: PropTypes.shape({
        '@id': PropTypes.string,
      }),
      '@type': PropTypes.string,
    }).isRequired,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        navigation_title: PropTypes.string,
      }),
    ).isRequired,
  };

  state = {};

  /**
   * Component did mount
   * @method componentDidMount
   * @returns {undefined}
   */
  componentDidMount() {
    const path = flattenToAppURL(this.props.content.parent['@id']);

    if (path !== '') {
      this.props.searchContent(path, {
        portal_type:
          this.props.content['@type'] === 'Lektion' ? 'Lektion' : 'Sprachmodul',
        fullobjects: 1,
        sort_on: 'getObjPositionInParent',
        depth: 1,
      });
    }
  }

  /**
   * Render method
   * @returns
   * @memberof NextPreviousNav
   * @returns {string} html
   */
  render() {
    const activeItem = this.props.content.id;
    const index = findIndex(this.props.items, (item) => item.id === activeItem);

    const previous = index > 0 ? this.props.items[index - 1] : false;
    const next =
      index < this.props.items.length ? this.props.items[index + 1] : false;

    return (
      <div className="previous-next-nav">
        {previous ? (
          <Button
            className="branded blue"
            as={Link}
            to={previous['@id']}
            size="medium"
          >
            <Icon name="chevron left" size="small" className="fontawesome" />
            Vorherige Lektion
          </Button>
        ) : (
          <div />
        )}
        {next && this.props.content['@type'] === 'Lektion' ? (
          <Button
            className="branded blue"
            as={Link}
            to={next['@id']}
            size="medium"
          >
            Nächste Lektion
            <Icon name="chevron right" size="small" className="fontawesome" />
          </Button>
        ) : (
          <div />
        )}
        {/* {this.props.content['@type'] !== 'Lektion' && (
          <Button className="branded blue" as={Link} to="/" size="medium">
            Zur Startseite
            <Icon name="chevron right" size="small" className="fontawesome" />
          </Button>
        )} */}
      </div>
    );
  }
}

export default connect(
  (state) => ({
    items: state.search.items,
  }),
  { searchContent },
)(NextPreviousNav);
